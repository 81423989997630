/* || RESET */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Courier New', Courier, monospace;
}
img {
 display: block;
 max-width: 100%;
 height: auto;
}
button {
 font: inherit;
}
li {
  list-style: none;
}
/* || VARIABLES */
:root {
  /* COLORS */
  --ARTICLE-COLOR: rgb(224,226,251);
}
/* || PAGE LAYOUT STYLES */
body {
  min-height: 100vh;
  display: flex;
  background-color: #efefef;
  justify-content: center;
}
/* #root {
  display: flex;
  flex: row nowrap;
} */
#root {
  display: grid;
  grid-template-columns: 200px calc(100vw - 200px);
  grid-template-rows: 100%;
  grid-template-areas: 
  "header-area    main-area"
  ;
}
.App {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

Footer {
  width: 100%;
  background-color: #c3def5;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.Home, .Accounts, .Cards, .Gold, .Messages {
  grid-area: main-area;
  display: flex;
  width: calc(100vw - 200px);
  flex-grow: 1;
  flex-direction: column;
  overflow-y: auto;
  background: rgb(22,26,42);
  background: linear-gradient(to bottom, rgb(22,26,42) 0%, rgb(44,36,54) 90%);
}
.display__none {
  display: none;
}

/* || HEADER */
  header{
    grid-area: header-area;
    background-color: rgb(35, 32, 51);
    width: 200px;
    height: 100%;
    position: fixed;
  }
  .side__figure {
    display: flex;
    height: 20vh;
    align-items: center;
    justify-content: center;
  }
  .side__figure__img {
    padding: 1rem;
  }
  .side__nav {
    background-color: var(--ARTICLE-COLOR);
    height: 80vh;
    border-radius: 60px 60px 0 0;
  }
  .side__nav__list {
    display: grid;
    height: 100%;
    font-size: 4rem;
    align-items: center;
    justify-content: center;
    grid-template-columns: auto;
    grid-template-rows: repeat(5, 1fr);
    grid-template-areas: 
    "home"
    "accounts"
    "cards"
    "stocks"
    "messages"
    ;
  }
side__nav__list-item:nth-child(1){
  grid-area: home;
}
side__nav__list-item:nth-child(1){
  grid-area: accounts;
}
side__nav__list-item:nth-child(1){
  grid-area: cards;
}
side__nav__list-item:nth-child(1){
  grid-area: stocks;
}
side__nav__list-item:nth-child(1){
  grid-area: messages;
}
.Home {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(4, 1fr);
  column-gap: 3rem;
  row-gap: 3rem;
  grid-template-areas: 
  "welcome    chart         chart"
  "messages   chart         chart"
  "balance    transactions  transactions"
  "credit     transactions  transactions"
  ;
  padding: 3rem;
}
.Home article:not(:nth-child(1)) {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.home__welcome {
  grid-area: welcome;
  color: var(--ARTICLE-COLOR);

}
.home__messages {
  grid-area: messages;
  background-color: var(--ARTICLE-COLOR);
  font-size: 2rem;
}
.home__balance {
  grid-area: balance;
  background-color: var(--ARTICLE-COLOR);
  font-size: 1.2rem;
}
/* HOME CREDIT */
.home__credit {
  grid-area: credit;
  background-color: var(--ARTICLE-COLOR);
  font-size: 1.2rem;
}
.home__credit__header {
  display: flex;
  flex: row nowrap;
  justify-content: space-between;
}
#home__balance-bar {
  display: flex;
  width: 100%;
  background-color: #4CAF50;
  }
#outstanding-bar {
  height: 35px;
  background-color: #da0303;
  text-align: center;
  line-height: 32px;
  
  color: black;
}
.home__credit__stats {
  display: flex;
  flex: row nowrap;
  justify-content: space-between;
}
.home__figure {
  grid-area: chart;
}
.home__figure img {
  height: 42vh;
  width: 66vw;
}
.home__transactions {
  grid-area: transactions;
  background-color: var(--ARTICLE-COLOR);
  font-size: 1rem;
}
.transactions__title:nth-child(2) {
  transform: translateX(38%);
}
.ter {
  display: flex;
  justify-content: space-between;
  text-align: right;
}
.ter p {
}
/* ///////////////////////////////////////////////// */
.Accounts {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(4, 1fr);
  column-gap: 3rem;
  row-gap: 3rem;
  grid-template-areas: 
  "linked-accounts    account-actions   account-actions"
  "linked-accounts    transaction       transaction"
  "linked-accounts    transaction       transaction"
  "account-balances   transaction       transaction"
  ;
  padding: 3rem;
}
.Accounts article {
  padding: 1rem;
  font-size: 2rem;
}
.accounts__favorite {
  grid-area: linked-accounts;
  background-color: var(--ARTICLE-COLOR);
}
.accounts__favorite >* {
  padding: .5rem;
}
.accounts__balances {
  grid-area: account-balances;
  background-color: var(--ARTICLE-COLOR);
}
.balances__div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.balances__div * {
  font-size: 1.96rem;
}
.account__actions {
  grid-area: account-actions;
  background-color: var(--ARTICLE-COLOR);
}
.actions__unordered-list {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.actions__list-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.list__icon {
  font-size: 3rem;
}
.account__transactions {
  grid-area: transaction;
  background-color: var(--ARTICLE-COLOR);
}
.account__transactions button {
  margin: 1rem 1rem 1rem 0;
}
.transactions__div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
/* ||Cards */
.Cards {
  display: grid;
  height: 100vh;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr 2fr 3fr;
  column-gap: 2rem;
  row-gap: 2rem;
  grid-template-areas: 
  "card         card          card          card"
  "summary      actions       actions       actions"
  "transaction  transaction   transaction   transaction"
  ;
  padding: 3rem;
}
/* CSS ART */
.card__container {
  grid-area: card;
  display: flex;
  justify-content: center;
}
.card__body {
  height: 200px;
  width: 340px;
  background: linear-gradient(-125deg, #83EBF3, #8B88FB, #3A39AD 50%);
  border-radius: 12px;
}
.card__bank-name {
  font-family: Signika;
  color: white;
  font-size: 1.4rem;
  font-weight: 400;
  opacity: 0.8;
  padding-left: 20px;
  padding-top: 20px;
  position: relative;
  display: inline-block;
}

.card__shadow {
  font-family: Share Tech Mono;
  color: transparent;
  text-shadow: -2px -2px 2px rgba(255,255,255,1),
                1px 1px 1px rgba(0,0,0,0.9),
                2px -2px 4px rgba(255,255,255,1),
                -2px -2px 2px rgba(0,0,0,0.9),
                -2px 2px 1px rgba(0,0,0,0.9);

}
.card__number {
  display: inline-block;
  padding-top: 60px;
  font-size: 1.4rem;
  padding-left: 20px;
  position: relative;
}
.card__good {
  display: inline-flex;
  color: rgba(255,255,255,1);
  padding-left: 20px;
}
.card__exp {
  padding-left: 1rem;
}
.card__holder {
  padding-left: 20px;
  padding-top: .5rem;
  font-size: 1.5rem;
}
.Cards article {
  background-color: var(--ARTICLE-COLOR);
  margin: 2rem;
  padding: 1rem;
}
.card__summary {
  grid-area: summary;
}
.card__actions {
  grid-area: actions;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.card__transactions {
  grid-area: transaction;
}
/* ////////////////// */
/* || GOLD */
.Gold {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, 1fr);
  column-gap: 3rem;
  row-gap: 3rem;
  grid-template-areas: 
  "salesPitch"
  "graph"
  "graph"
  ;
  padding: 3rem;
  height: 100vh;
}
.gold__top {
  grid-area: salesPitch;
}
.gold__top-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.gold__figure {
  min-width: 300px;
  max-width: 420px;
}
.gold__article {
  width: auto;
  font-size: 1.2rem;
  color: beige;
}
.gold__graph-div {
  grid-area: graph;
  margin: auto;
}
/* || MESSAGES */
.Messages {
  display: flex;
  flex: column nowrap;
  justify-content: center;
}
.messages__title, .messages__article {
  background-color: var(--ARTICLE-COLOR);
  margin: 2rem;
  padding: 1rem;
  font-size: 2rem;
  border-radius: 12px;
}
.messages__title {
  text-align: center;
}
.messages__article details summary {
  font-weight: bold;
}
